<template>
  <page-layout>
    <div
      class="w-100 d-flex justify-content-center"
    >
      <b-card
        header="User Approval Request"
        header-bg-variant="primary"
        header-text-variant="white"
        class="mt-4"
      >
        <b-spinner
          variant="primary"
          v-show="!requestStatus"
        />
        <b-card-title
          v-show="requestStatus"
          class="text-center mb-4"
        >
          {{ messages.getLabelApprovalRequestStatus({
            email: $store.state.approvalRequest.requestingUserEmail,
            requestStatus
          }) }}
        </b-card-title>
        <div
          v-if="$store.state.approvalRequest.approvalStatus === 'Pending'"
        >
          <b-card-sub-title
            class="text-center mb-4"
            :sub-title="`${messages.getLabelApprovalRequestDecision()}:`"
          />
          <div
            class="d-flex justify-content-around mb-3"
          >
            <b-button
              variant="primary"
              @click="makeDecision('approve')"
              size="lg"
              :disabled="disableApprove"
            >
              <span
                v-show="!loadingApprove"
              >
                {{ messages.getActionApprove() }}
              </span>
              <b-spinner
                small
                v-show="loadingApprove"
              />
            </b-button>
            <b-button
              variant="danger"
              @click="makeDecision('reject')"
              size="lg"
              :disabled="disableReject"
            >
              <span
                v-show="!loadingReject"
              >
                Reject
              </span>
              <b-spinner
                small
                v-show="loadingReject"
              />
            </b-button>
          </div>
          <b-card-sub-title
            :sub-title="messages.getLabelUserExpirationDate()"
            class="text-center mb-2"
          />
          <div
            class="d-flex justify-content-center"
          >
            <b-calendar
              v-model="expirationDate"
            />
          </div>
        </div>
      </b-card>
    </div>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import PageLayout from '../components/PageLayout'
import axios from 'axios'
import { APPROVED, REJECTED, PENDING } from '@/constants/approval_status'

export default {
  name: 'ApprovalRequest',
  components: { PageLayout },
  data () {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 3)
    return {
      loading: '',
      loadingApprove: false,
      disableApprove: false,
      loadingReject: false,
      disableReject: false,
      shouldDisplayDatePicker: false,
      expirationDate: expirationDate.toISOString().substr(0, 10),
      messages
    }
  },
  mounted () {
    this.getApprovalRequestInfo()
  },
  computed: {
    requestStatus () {
      const { approvalStatus } = this.$store.state.approvalRequest
      if ([APPROVED, PENDING, REJECTED].includes(approvalStatus)) {
        return approvalStatus
      }
      return null
    },
    idToken () {
      return this.$store.state.auth.idToken
    }
  },
  watch: {
    idToken: function () {
      this.getApprovalRequestInfo()
    },
    loading: function (val) {
      if (val === 'approve') {
        this.loadingApprove = true
        this.disableReject = true
      } else if (val === 'reject') {
        this.loadingReject = true
        this.disableApprove = true
      } else if (!val) {
        this.loadingReject = false
        this.loadingApprove = false
        this.disableApprove = false
        this.disableReject = false
      }
    }
  },
  methods: {
    getApprovalRequestInfo () {
      this.$store.dispatch('getUserApprovalRequest', this.$route.params.requestID)
        .finally(() => {
          this.loading = false
        })
    },
    makeDecision (status) {
      this.loading = status
      const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/approval_requests/${this.$route.params.requestID}`
      axios.put(url, { status: status, expiration_date: this.expirationDate })
        .then(() => {
          this.getApprovalRequestInfo()
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
